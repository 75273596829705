import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import { Section, Button, Title, Text, Box } from '../components/Core'
import PageWrapper from '../components/PageWrapper'
import Contact from '../sections/common/Contact'
import imgWorkCover from '../assets/image/cover/tv -db.jpg'
import imgS1 from '../assets/image/details/studiodb/blue-back.jpg'
import imgS3 from '../assets/image/details/studiodb/dj.jpg'
import imgS4 from '../assets/image/details/studiodb/Leilasquat.jpg'
import imgS5 from '../assets/image/details/studiodb/details-3.jpg'
import imgS2 from '../assets/image/details/studiodb/Leila-floor.jpg'
import FashionSubheader from '../components/FashionSubheader'

const WorkSingle = () => {
  return (
    <>
      <PageWrapper>
        <Container
          fluid
          className="px-sm-5"
          css={`
            margin-top: 92px;
          `}
        >
          <img src={imgWorkCover} alt="" className="img-fluid w-100" />
        </Container>
        <Section className="mt-lg-5">
          <Container>
            <Row>
              <Col lg="8">
                <Text variant="tag">Styling</Text>
                <Title variant="secSm" className="my-4">
                  Project Overview{' '}
                </Title>
                <Text
                  variant="p"
                  css={`
                    max-width: 750px;
                  `}
                >
                  {' '}
                  Studio dB is an open space with attractive features for
                  photo/film productions, digital events, AV rehearsal,
                  screenings & workshops, and performances. I decided to use it
                  for a promo event featuring legendary Berlin clubbing icon
                  Leila Dörte.
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <div className="mt-lg-3">
          <FashionSubheader
            client={'Studio dB'}
            credits={[
              'Photography: Florian Geiss',
              'Styling: Bernadetta Nycz',
              'Model: Leila Dörte ',
            ]}
            buttonText="Client Website"
            buttonDestination="https://www.instagram.com/studio.db.berlin/?hl=en"
          />
        </div>
        <Section className="mt-lg-5">
          <Container>
            <Row>

              <Col xs="12" className="mb-5">
                <img src={imgS1} alt="" className="img-fluid w-100" />
              </Col>

              <Col xs="12" className="mb-5">
                <img src={imgS2} alt="" className="img-fluid w-100" />
              </Col>
              <Col lg="6" className="mb-5 mb-lg-0 pr-lg-4">
                <img src={imgS3} alt="" className="img-fluid w-100" />
              </Col>

              <Col lg="6" className="pl-lg-4">
                <img src={imgS4} alt="" className="img-fluid w-100" />
              </Col>

              <Col xs="12" className="mb-5">
                <img src={imgS5} alt="" className="img-fluid w-100" />
              </Col>



            </Row>
          </Container>
        </Section>
        <Section className="pt-4 pb-0"></Section>
        {/* <Section mt="22%" bg="dark">
          <Container>
            {/* <Row

              css={`
                margin-top: -40%;
              `}
            >
              { <Col xs="12" className="mb-5">
                <img src={imgS5} alt="" className="img-fluid w-100" />
              </Col>}
            </Row> }

            <div className="text-center mt-lg-5">
              <Text variant="tag" className="mb-1" color="lightShade">
                Next Project
              </Text>
              <Link to="portfolio-details">
                <Button
                  arrowRight
                  className="border-0 bg-transparent shadow-none text-capitalize py-3"
                  css={`
                    font-weight: 700
                    font-size: 1.5rem;
                    letter-spacing: -1.2px;
                    line-height: 1.375;
                    @media ${device.md} {
                      font-size: 2rem;
                    }
                `}
                >
                  Replace
                </Button>
              </Link>
            </div>
          </Container>
        </Section> */}
        <Box py={4}>
          <Contact />
        </Box>
      </PageWrapper>
    </>
  )
}
export default WorkSingle
